import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h1 className="title has-text-midnightBlue is-size-4-mobile">Thanks for registering!</h1>

          <p className="has-text-midnightBlue is-size-5 is-size-6-mobile">
            We'll validate your result photo and send out your certificate as soon as possible. Most people receive
            their certificate in a few hours but please allow up to 12 hours from when you submitted your result photo.
          </p>

          <p className="mt-3 has-text-midnightBlue is-size-5 is-size-6-mobile">
            If you haven't received your certificate within 12 hours please check your "spam"/"junk" mailbox before
            contacting us.
          </p>

          <hr />

          <p className="mt-3">
            <Link to="/register">Register another kit</Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
